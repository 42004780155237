import React, { useEffect } from "react";

import {
  Anchor,
  Button,
  Paper,
  PasswordInput,
  TextInput,
  Title,
  Text,
  Group,
  Container,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuth } from "providers/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ISessionAuthTokens } from "types/api/authTypes";
import {
  showErrorNotification,
  showSuccessNotification,
} from "utils/notifications";

import AuthLayout from "./components/AuthLayout";
import SocialButtons from "./components/SocialButtons";
import ReactGA from "react-ga4";

interface FormData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  repeatPassword: string;
}

const SignUpPage = (): JSX.Element => {
  const { register } = useAuth();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_uri");
  const navigate = useNavigate();

  const { user, isRegisterLoading } = useAuth();

  useEffect(() => { 
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "AC Register" });
  },[])

  const form = useForm<FormData>({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      repeatPassword: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length <= 6
          ? "Password should include at least 6 characters"
          : null,
      repeatPassword: (value, values) =>
        value === values.password ? null : "Passwords don't match",
    },
  });

  const handleSubmit = (formData: FormData): void => {
    register(formData)
      .then(() => {
        const url = "select-subscription";
        navigate(`/${url}`);

        ReactGA.event({
          category: "Register Button",
          action: "register",
        });

        return ;
      })
      .catch(() => {
        showErrorNotification("Error", "Registration is failed");
      });
  };

  return (
    <AuthLayout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Container size={420} my={40}>
          <Paper shadow="xl" p={30} mt={30} radius="md">
            <Title fw={600} mb="lg" style={{align: "center"}}>
              Sign Up
            </Title>
            <SocialButtons />
            <Divider
              label="Or continue with email"
              labelPosition="center"
              my="md"
            />
            <TextInput
              label="Name"
              placeholder="Harry"
              required
              {...form.getInputProps("first_name")}
            />
            <TextInput
              label="Surname"
              placeholder="Potter"
              required
              mt="sm"
              {...form.getInputProps("last_name")}
            />
            <TextInput
              label="Email"
              placeholder="your@mail.com"
              required
              mt="sm"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              mt="sm"
              {...form.getInputProps("password")}
            />
            <PasswordInput
              label="Repeat password"
              placeholder="Repeat your password"
              required
              mt="sm"
              {...form.getInputProps("repeatPassword")}
            />
            <Group justify="space-between" mt="lg">
              <Anchor<"a"> href="/forgot-password" size="sm" fw={600}>
                Forgot password?
              </Anchor>
            </Group>
            <Button type="submit" mt="xl" loading={isRegisterLoading} fullWidth>
              Register
            </Button>
            <Text mt="lg" color="dimmed" size="sm" mx="auto" style={{ align: "center" }}>
              Click &quot;Register&quot; to agree to Assessment Center&apos;s
              <br />
              <Anchor
                href="https://ameaningoflife.org/terms-of-service/"
                target="_blank"
              >
                Terms of Service
              </Anchor>{" "}
              and acknowledge that Assessment Center&apos;s{" "}
              <Anchor
                href="https://ameaningoflife.org/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </Anchor>{" "}
              applies to you.
            </Text>
          </Paper>
        </Container>
      </form>
    </AuthLayout>
  );
};

export default SignUpPage;
